import Section from '../BlockElement/Section/Section';
import './Hero.scss';
import Button from "../FormElement/Button/Button";
import Container from "../BlockElement/Container/Container";
import { FaCircleArrowRight } from "react-icons/fa6";

export default function Hero(){
    return (
    <Section>
      <Container className="hero__container">
                <Container className="hero__background">
                <Container className="hero__text">
                    Perfekcyjny uśmiech <br/>zaczyna się od <br/>zdrowych zębów
                </Container>
                <Container className="smaller__text">
                    Kompleksowa opieka ortodontyczna <br/> i stomatologiczna
                </Container>
                <Container className="smaller__button">
                <Button className="blue small">Umów wizytę</Button>
                </Container>
                <Container className="floating__banner">
                <div className="arrow-container">
                    <FaCircleArrowRight className="arrow" />
                </div>
                <div className="floating__banner-text">Leczenie<br />nakładkowe</div>
                </Container>
                </Container>
            </Container>
    </Section>
    );
}