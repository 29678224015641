import React from 'react';
import { Carousel } from 'antd';
import Section from '../BlockElement/Section/Section';
import './Team.scss';
import { teamMembers } from '../../utils/TeamMembers'; 
import { ITeamMember } from '../../types/ITeamMember'; 
import cardImage from '../../assets/card.png'; 
import Container from '../BlockElement/Container/Container'; 

export default function Team() {
  return (
    <Section className="team-section">
      <h2 className="section-title">Nasz zespół</h2>
      <Container className="section-team">
        <Carousel
          arrows
          infinite
          slidesToShow={4}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {teamMembers.map((member: ITeamMember, index: number) => (
            <Container key={index} className="team-card">
              <Container className="image-overlay-container">
                <img src={cardImage} alt={member.name} className="team-card-image" />
                <Container className="overlay-text">
                  <h3 className="team-card-title">{member.name}</h3>
                  <p className="team-card-description">{member.description}</p>
                </Container>
              </Container>
            </Container>
          ))}
        </Carousel>
      </Container>
    </Section>
  );
}
