import React, { useEffect, useState } from "react";
import "./Specification.scss";
import Page from "../../components/StructureElement/Page/Page";
import { useParams } from "react-router-dom";
import Article from "../../components/BlockElement/Article/Article";

export default function Specification(){
    const { service } = useParams();
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        const fetchTemplate = async () => {
            try{
                const response = await fetch(`/html/${service}.html`);
                if (response.ok) {
                    const content = await response.text();
                    setHtmlContent(content);
                } else {
                    setHtmlContent('<p>Service not found</p>');
                }
            }catch(error: any){
                console.error("Error loading html template", error);
                setHtmlContent('<p>Service not found</p>');
            }
        };

        fetchTemplate();
    }, [service]);

    return(
        <Page>
            <Article htmlContent={htmlContent} />
        </Page>
    );
}