import Section from '../BlockElement/Section/Section';
import Description from '../Description/Description';
import './AboutUs.scss';
import { aboutUsContent } from '../../utils/AboutUsData';

export default function AboutUs(){
    return (
        <Section className="about-us-section">
        <Description
                title={aboutUsContent.title}
                text={aboutUsContent.text}
                color={aboutUsContent.color}
            />
    </Section>
    );
}