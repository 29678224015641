
import { IDepartment } from "../types/IDepartment";


export const departments: IDepartment[] = [
    {
        title: 'Ortodoncja',
        services: ['Lorem ipsum dolor', 'Lorem ipsum dolor', 'Lorem ipsum dolor','Lorem ipsum dolor', 'Lorem ipsum dolor', 'Lorem ipsum dolor']
    },
    { 
        title: 'Implantologia', 
        services: ['Lorem ipsum dolor', 'Lorem ipsum dolor', 'Lorem ipsum dolor', 'Lorem ipsum dolor', 'Lorem ipsum dolor','Lorem ipsum dolor']
    },
    { 
        title: 'Chirurgia', 
        services: ['Lorem ipsum dolor', 'Lorem ipsum dolor', 'Lorem ipsum dolor','Lorem ipsum dolor', 'Lorem ipsum dolor', 'Lorem ipsum dolor']
    },
    { 
        title: 'Protetyka', 
        services: ['Lorem ipsum dolor', 'Lorem ipsum dolor', 'Lorem ipsum dolor', 'Lorem ipsum dolor', 'Lorem ipsum dolor','Lorem ipsum dolor']
    }
];
