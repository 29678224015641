import Section from '../BlockElement/Section/Section';
import './Equipment.scss';
import stuff1 from '../../assets/stuff1.png';  
import stuff2 from '../../assets/stuff2.png';
import Container from '../BlockElement/Container/Container';
import Description from '../Description/Description';
import { equipmentContent } from '../../utils/EquipmentContent';

export default function Equipment(){
    return (
    <Section>
        <Container className="equipment-container">
        <Container className="equipment-images">
          <Container className="equipment-item">
            <img src={stuff1} alt="Equipment 1" />
            <p>Lorem Ipsum</p>
          </Container>
          <Container className="equipment-item">
            <img src={stuff2} alt="Equipment 2" />
            <p>Lorem Ipsum</p>
          </Container>
          <Container className="equipment-item">
            <img src={stuff1} alt="Equipment 2" />
            <p>Lorem Ipsum</p>
          </Container>
        </Container>
        <Container className="equipment-info">
        <Description 
            title={equipmentContent.title} 
            text={equipmentContent.text} 
            color={equipmentContent.color} 
          />
        </Container>
      </Container>
    </Section>
    );
}