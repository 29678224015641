import React from "react";
import "./Prices.scss";
import Page from "../../components/StructureElement/Page/Page";

export default function Prices(){
    return(
        <Page>

        </Page>
    );
}