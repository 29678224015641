import { ITeamMember } from '../types/ITeamMember';

const member1: ITeamMember = {
  name: 'lek. dent. Magdalena Sordyl',
  image: '../../assets/card.png', 
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
};

const member2: ITeamMember = {
  name: 'lek. dent. Magdalena Sordyl',
  image: '../../assets/card.png', 
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
};

const member3: ITeamMember = {
  name: 'lek. dent. Magdalena Sordyl',
  image: '../../assets/card.png', 
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
};

const member4: ITeamMember = {
  name: 'lek. dent. Magdalena Sordyl',
  image: '../../assets/card.png', 
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
};

export const teamMembers: ITeamMember[] = [member1, member2, member3, member4];
