import Container from '../BlockElement/Container/Container';
import './Service.scss';

export default function Service({ title }: { title: string }) {
    return (
        <Container className="service">
            <span className="arrow"></span>
            <span>{title}</span>
            <span className="arrow">→</span> 
        </Container>
    );
}