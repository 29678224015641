import React, { useState, useEffect } from "react";
import "./Navbar.scss";
import { IStructureElement } from "../../../types/IStructureElement";
import Nav from "../../BlockElement/Nav/Nav";
import logo from "../../../assets/logo.png";
import Button from "../../FormElement/Button/Button";
import Link from "../../MediaElement/Link/Link"; 
import Container from "../../BlockElement/Container/Container";
import { FaFacebook, FaInstagram } from 'react-icons/fa'; 
import { MenuOutlined } from "@ant-design/icons";

export default function Navbar({ id, className }: IStructureElement) {
    const [isMobile, setIsMobile] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 786);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };
    
    return(
        <Nav id={id} className={className}>
        <Container className="navbar__container">
            <Link href="/" className="navbar__logo">
                <img src={logo} alt="Logo" />
            </Link>
            {!isMobile ? (
                <Container className="navbar__menu">
                    <Container className="navbar__item">
                        <Link href="/onas">O nas</Link>
                    </Container>
                    <Container className="navbar__item">
                        <Link href="/uslugi">Usługi</Link>
                    </Container>
                    <Container className="navbar__item">
                        <Link href="/cennik">Cennik</Link>
                    </Container>
                    <Button className="blue small">Umów wizytę</Button>
                </Container>
            ) : (
                <button className="navbar__hamburger" onClick={toggleMenu}>
                    <MenuOutlined />
                </button>
            )}

            <Container className="navbar__social-icons">
                <Link href="https://www.facebook.com" target="_blank" >
                    <FaFacebook className="social-icon" />
                </Link>
                <Link href="https://www.instagram.com" target="_blank" >
                    <FaInstagram className="social-icon" />
                </Link>
            </Container>
        </Container>
        
        {isMobile && menuOpen && (
            <Container className="navbar__drawer">
                <Container className="navbar__drawer-close" onClick={closeMenu}>
                    &times;
                </Container>
                <Container className="navbar__drawer-menu">
                    <Link href="/onas" onClick={closeMenu}>O nas</Link>
                    <Link href="/uslugi" onClick={closeMenu}>Usługi</Link>
                    <Link href="/cennik" onClick={closeMenu}>Cennik</Link>
                    <Button className="blue small" onClick={closeMenu}>Umów wizytę</Button>
                </Container>
            </Container>
        )}
    </Nav>
    );  
}