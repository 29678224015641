import { IReviewCardProps } from '../types/IReviewCardProps';

export const reviews: IReviewCardProps[] = [
    {
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
        background: 'opinion1'
    },
    {
        text: `“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”.`,
        background: 'opinion2'
    },
    {
        text: `“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”.`,
        background: 'opinion1'
    },
    {
        text: `“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”.`,
        background: 'opinion2'
    }
];
